import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';

export const useUserSelection = ({ fetchUrl, maxSelections, filterRules, onUsersLoaded }) => {
    const [availableUsers, setAvailableUsers] = useState([]);
    const [selections, setSelections] = useState({});
    const [loading, setLoading] = useState(false);
    const previousFetchUrl = useRef(fetchUrl);

    const fetchUsers = useCallback(async () => {
        if (!fetchUrl) {
            setAvailableUsers([]);
            if (onUsersLoaded) onUsersLoaded();
            return;
        }

        setLoading(true);
        try {
            const response = await axios.get(fetchUrl);
            const userData = Array.isArray(response.data) ? response.data : [];
            setAvailableUsers(userData);
        } catch (error) {
            console.error('Error fetching users:', error);
            setAvailableUsers([]);
        } finally {
            setLoading(false);
            if (onUsersLoaded) onUsersLoaded();
        }
    }, [fetchUrl, onUsersLoaded]);



    useEffect(() => {
        if (fetchUrl && fetchUrl !== previousFetchUrl.current) {
            fetchUsers();
            previousFetchUrl.current = fetchUrl;
        }
    }, [fetchUrl, fetchUsers]);


    const handleSelect = (role, user) => {
        setSelections((prevSelections) => {
            if (role === 'members' || role === 'coaches') {
                const updatedRoleSelections = prevSelections[role]
                    ? [...prevSelections[role], user]
                    : [user];
                return { ...prevSelections, [role]: updatedRoleSelections };
            }
            return { ...prevSelections, [role]: user };
        });
    };

    const handleRemove = (role, userId) => {
        setSelections((prevSelections) => {
            if (role === 'members' || role === 'coaches') {
                const updatedRoleSelections = prevSelections[role]?.filter(
                    (user) => user.id !== userId
                );
                return { ...prevSelections, [role]: updatedRoleSelections };
            }
            const updatedSelections = { ...prevSelections };
            delete updatedSelections[role];
            return updatedSelections;
        });
    };

    // Add detailed logging to getFilteredUsers
    const getFilteredUsers = (filterKey) => {
        console.log('[UseUserSelection] getFilteredUsers called:', {
            filterKey,
            availableUsers,
            isArray: Array.isArray(availableUsers),
            availableUsersType: typeof availableUsers,
            filterRules
        });

        if (!Array.isArray(availableUsers)) {
            console.warn('[UseUserSelection] availableUsers is not an array:', {
                value: availableUsers,
                type: typeof availableUsers
            });
            return [];
        }

        try {
            const filterRule = filterRules[filterKey] || (() => true);
            console.log('[UseUserSelection] Using filterRule:', filterRule);
            const filtered = availableUsers.filter((user) => filterRule(user, selections));
            console.log('[UseUserSelection] Filtered results:', filtered);
            return filtered;
        } catch (error) {
            console.error('[UseUserSelection] Error in getFilteredUsers:', error);
            return [];
        }
    };

    return {
        selections,
        availableUsers,
        handleSelect,
        handleRemove,
        getFilteredUsers,
        setSelections,
        fetchUsers,
        loading
    };
};
