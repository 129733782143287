import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Typography, 
    Button, 
    Grid, 
    Box,
    Select,
    MenuItem
} from '@mui/material';
import TeamCard from './TeamCard';
import './css/Teams.css';
import CreateTeamPanel from "./CreateTeamPanel";
import config from '../../config';
import DeleteModal from "../../components/deletemodal/DeleteModal";


const Teams = () => {
    const [teams, setTeams] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const [isCreateTeamOpen, setIsCreateTeamOpen] = useState(false);
    const [selectedTeamId, setSelectedTeamId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [teamToDelete, setTeamToDelete] = useState(null);

    const fetchTeams = async (companyId = null) => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/team/user-teams/`);
            setTeams(response.data);
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    const handleCreateTeam = () => {
        setSelectedTeamId(null);
        setIsCreateTeamOpen(true);
    };

    const closeCreateTeam = () => {
        setIsCreateTeamOpen(false);
        setSelectedTeamId(null);
    };

    const handleTeamCardClick = (teamId) => {
        setSelectedTeamId(teamId);
        setIsCreateTeamOpen(true);
    };

    const handleDeleteClick = (team) => {
        setTeamToDelete(team);
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        setTeamToDelete(null);
    };

    const deleteTeam = async () => {
        try {
            await axios.delete(`${config.backendUrlApiV1}/team/${teamToDelete.id}/`);
            await fetchTeams();
            closeDeleteModal();
        } catch (error) {
            console.error('Error deleting team:', error);
        }
    };

    useEffect(() => {
        fetchUserData();
    }, []);

    const fetchUserData = async () => {
        try {
            const [teamsResponse, companiesResponse] = await Promise.all([
                axios.get(`${config.backendUrlApiV1}/team/user-teams/`),
                axios.get(`${config.backendUrlApiV1}/companies/user-companies/`)
            ]);
    
            setTeams(teamsResponse.data);
            setCompanies(companiesResponse.data);
    
            if (companiesResponse.data.length === 1) {
                setSelectedCompany(companiesResponse.data[0]);
    
                // Assuming the role is part of the company object
                const userRoleFromAPI = companiesResponse.data[0].role || 'member';
                setUserRole(userRoleFromAPI);
            } else {
                // Handle cases with multiple companies if needed
                setUserRole('member'); // Default role if not provided
            }
        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setLoading(false);
        }
    };
    

    const canCreateTeam = () => {
        return true
        // return userRole === 'admin' || userRole === 'superuser' || userRole === 'member';
    };
    

    const canDeleteTeam = (team) => {
        return true
        // return userRole === 'admin' || userRole === 'superuser';
    };

    const handleCompanyChange = (event) => {
        const companyId = event.target.value;
        setSelectedCompany(companies.find(c => c.id === companyId));
        fetchTeams(companyId);
    };

    return (
        <Box className="teams-container">
            <Box className="teams-header">
                <Typography variant="h5" className="teams-title">Team Management</Typography>
                {/* {companies.length > 1 && (
                    <Select
                        value={selectedCompany?.id || ''}
                        onChange={handleCompanyChange}
                        className="company-select"
                    >
                        {companies.map((company) => (
                            <MenuItem key={company.id} value={company.id}>
                                {company.name}
                            </MenuItem>
                        ))}
                    </Select>
                )} */}
                {canCreateTeam() && (
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={handleCreateTeam}
                    >
                        Create New Team
                    </Button>
                )}
            </Box>
            <Box className="teams-grid-container">
                {loading ? (
                    <Typography>Loading teams...</Typography>
                ) : teams.length === 0 ? (
                    <Typography variant="body1" className="no-teams-message">
                        {canCreateTeam()
                            ? "Create a team"
                            : "No teams available to display"}
                    </Typography>
                ) : (
                    <Grid container spacing={2} className="teams-grid">
                        {teams.map((team) => (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={team.id}>
                                <TeamCard
                                    team={team}
                                    onClick={() => handleTeamCardClick(team.id)}
                                    onDeleteClick={() => handleDeleteClick(team)}
                                    showDeleteButton={canDeleteTeam(team)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                )}
            </Box>
            <CreateTeamPanel
                isOpen={isCreateTeamOpen}
                onClose={closeCreateTeam}
                teamId={selectedTeamId}
                onSave={fetchTeams}
            />
            <DeleteModal
                isOpen={isDeleteModalOpen}
                onClose={closeDeleteModal}
                onDelete={deleteTeam}
                title="Delete Team"
                message={`Are you sure you want to delete the team "${teamToDelete?.name}"? This action cannot be undone.`}
            />
        </Box>
    );
};

export default Teams;
