import React from 'react';
import { Tooltip, Chip, Avatar } from '@mui/material';
import './UserChip.css';

const UserChip = ({ user, onDelete }) => {
    const displayName = user.name || user.firstName || user.email || 'Unknown';
    const avatarText = displayName.charAt(0).toUpperCase();

    const chipProps = {
        avatar: <Avatar>{avatarText}</Avatar>,
        label: displayName,
        className: "user-chip"
    };

    if (onDelete) {
        chipProps.onDelete = onDelete;
    }

    return (
        <Tooltip title={user.email} arrow>
            <Chip
                avatar={
                    <Avatar>
                        {(user.name || user.email).charAt(0).toUpperCase()}
                    </Avatar>
                }
                label={user.name || user.email}
                onDelete={onDelete}
                className="member-chip"
            />
        </Tooltip>
    );
};

export default UserChip;
