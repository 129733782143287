import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    CircularProgress,
    IconButton,
    Typography,
    Alert,
    Button,
    MenuItem,
    FormControl,
    Select
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import './TranscriptEditor.css';
import ModalOverlay from "../../../components/modal/ModalOverlay";
import config from "../../../config";
import { useUserSelection } from "../../../components/usersearchinput/UseUserSelection";
import { UserSelectionInput } from "../../../components/usersearchinput/UserSelection";
import { useRefresh } from "../../../auth/RefreshProvider";

const TranscriptEditor = ({ isOpen, onClose, transcript, onSave }) => {
    // Core states
    const [selectedTeamId, setSelectedTeamId] = useState('');
    const [teams, setTeams] = useState({ selected: null, meetings: [] });
    const [speakerDetails, setSpeakerDetails] = useState([]);
    const [audioData, setAudioData] = useState({});
    
    // Loading states
    const [loadingInitial, setLoadingInitial] = useState(true);
    const [loadingAudio, setLoadingAudio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [usersLoaded, setUsersLoaded] = useState(false);

    // Audio states
    const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [activeSource, setActiveSource] = useState(null);
    const audioContextRef = useRef(null);
    const { triggerRefresh } = useRefresh();

    const {
        selections,
        handleSelect,
        handleRemove,
        getFilteredUsers,
        setSelections,
        users,
        loading: usersLoading,
        fetchUsers
    } = useUserSelection({
        fetchUrl: selectedTeamId ? `${config.backendUrlApiV1}/team-members/${selectedTeamId}/` : null,
        maxSelections: {},
        filterRules: {
            default: () => true
        },
        onUsersLoaded: () => setUsersLoaded(true)
    });

    // Fetch initial data
    const fetchInitialData = useCallback(async () => {
        try {
            setLoadingInitial(true);
            setError('');

            const response = await axios.get(
                `${config.backendUrlApiV1}/transcript/${transcript.metaData.transcriptId}/get_speakers_initial/`,
                {
                    params: {
                        meetingId: transcript.metaData.meetingId
                    }
                }
            );

            const teamsData = response.data.teams || { selected: null, meetings: [] };
            setTeams(teamsData);
            setSelectedTeamId(teamsData.selected?.id || '');

            const processedSpeakers = (response.data.speakers || []).map(speaker => ({
                ...speaker,
                speakerName: speaker.speakerName,
            }));
            setSpeakerDetails(processedSpeakers);

            // Set initial selections
            const initialSelections = {};
            processedSpeakers.forEach(speaker => {
                if (speaker.speakerName && speaker.userId) {
                    initialSelections[speaker.speakerId] = {
                        id: speaker.userId,
                        name: speaker.speakerName,
                        email: speaker.email
                    };
                }
            });
            setSelections(initialSelections);

        } catch (error) {
            console.error('Error fetching initial data:', error);
            setError('Failed to fetch speaker data');
        } finally {
            setLoadingInitial(false);
        }
    }, [transcript, setSelections]);

    // Fetch audio data
    const fetchAudioData = useCallback(async () => {
        try {
            setLoadingAudio(true);
            const response = await axios.get(
                `${config.backendUrlApiV1}/transcript/${transcript.metaData.transcriptId}/get_audio_snippets/`,
                {
                    params: { meetingId: transcript.metaData.meetingId }
                }
            );
            setAudioData(response.data.audioSnippets || {});
        } catch (error) {
            console.error('Error fetching audio data:', error);
        } finally {
            setLoadingAudio(false);
        }
    }, [transcript]);

    // Handle team selection
    const handleTeamChange = useCallback((event) => {
        const newTeamId = event.target.value;
        setSelectedTeamId(newTeamId);
        setUsersLoaded(false);
        if (newTeamId) {
            fetchUsers();
        }
    }, [fetchUsers]);

    // Initialize data when modal opens
    useEffect(() => {
        if (isOpen) {
            fetchInitialData();
            fetchAudioData();
        }
        return () => {
            setSelectedTeamId('');
            setUsersLoaded(false);
            if (activeSource) {
                activeSource.stop();
            }
            if (audioContextRef.current?.state !== 'closed') {
                audioContextRef.current?.close();
            }
        };
    }, [isOpen, fetchInitialData, fetchAudioData]);

    const handleSave = async () => {
        try {
            setLoading(true);
            setError('');

            const updates = Object.entries(selections).map(([speakerId, user]) => ({
                speaker_id: speakerId,
                name: user ? user.name : '',
                user_id: user?.id,
            }));

            const response = await axios.post(
                `${config.backendUrlApiV1}/transcript/${transcript.metaData.transcriptId}/edit_speaker_bulk/`,
                {
                    updates,
                    meetingId: transcript.metaData.meetingId,
                    teamId: selectedTeamId || null,
                }
            );

            if (response.status === 200) {
                const updatedDetails = {
                    team: response.data.updatedTeam,
                    participants: response.data.updatedParticipants,
                };
                
                // First trigger the refresh to ensure data is reloaded
                triggerRefresh();
                
                // Then call onSave with the updated details
                await onSave(updatedDetails);
                
                // Finally close the modal
                onClose();
            }
        } catch (error) {
            console.error('Error saving speaker assignments:', error);
            setError('Failed to save speaker assignments');
        } finally {
            setLoading(false);
        }
    };


    
    // const handleSave = async () => {
    //     try {
    //         setLoading(true);
    //         setError('');
    
    //         const updates = Object.entries(selections).map(([speakerId, user]) => ({
    //             speaker_id_original: speakerId,
    //             name: user ? user.name : '',
    //             user_id: user?.id,
    //         }));
    
    //         const response = await axios.post(
    //             `${config.backendUrlApiV1}/transcript/${transcript.metaData.transcriptId}/edit_speaker_bulk/`,
    //             {
    //                 updates,
    //                 meetingId: transcript.metaData.meetingId,
    //                 teamId: selectedTeamId || null,
    //             }
    //         );
    
    //         if (response.status === 200) {
    //             const updatedDetails = {
    //                 team: response.data.updatedTeam, // Example structure
    //                 participants: response.data.updatedParticipants,
    //             };
    //             onSave(updatedDetails);
    //             onClose();
    //             triggerRefresh();
    //         }
    //     } catch (error) {
    //         console.error('Error saving speaker assignments:', error);
    //         setError('Failed to save speaker assignments');
    //     } finally {
    //         setLoading(false);
    //     }
    // };
    
    const renderSpeakerSection = useCallback((speaker, index) => {
        const availableUsers = selectedTeamId && usersLoaded ? 
            (getFilteredUsers?.('default') || []) : [];

        return (
            <Box key={index} className="transcript-line">
                <Box className="speaker-section">
                    <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
                        <Box
                            component="span"
                            sx={{
                                backgroundColor: '#f5f5f5',
                                padding: '4px 8px',
                                borderRadius: '4px',
                                fontSize: '0.875rem',
                                color: 'text.secondary',
                                display: 'inline-flex',
                                alignItems: 'center',
                            }}
                        >
                            Current: {speaker.speakerName || `Speaker ${speaker.speakerId}`}
                        </Box>
                    </Box>

                    {selectedTeamId ? (
                        usersLoading || !usersLoaded ? (
                            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">
                                    Loading team members...
                                </Typography>
                            </Box>
                        ) : (
                            <UserSelectionInput
                                role={`${speaker.speakerId}`}
                                availableUsers={availableUsers}
                                selectedUser={selections[`${speaker.speakerId}`]}
                                onSelect={handleSelect}
                                onRemove={handleRemove}
                                placeholder="Select team member to assign..."
                            />
                        )
                    ) : (
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                            Please select a team to assign speakers
                        </Typography>
                    )}

                    {audioData[speaker.speakerId] && (
                        <Box className="audio-row" mt={1}>
                            <Box className="audio-section">
                                <IconButton
                                    onClick={() => togglePlayPause(index, audioData[speaker.speakerId].audioData)}
                                    className="play-button"
                                    size="small"
                                >
                                    {currentPlayingIndex === index && isPlaying ? (
                                        <PauseIcon fontSize="small" />
                                    ) : (
                                        <PlayArrowIcon fontSize="small" />
                                    )}
                                </IconButton>
                                <Box className="slider-container">
                                    <Typography variant="caption" className="time-info">
                                        Original timing: {formatTime(audioData[speaker.speakerId].startTime)} - {formatTime(audioData[speaker.speakerId].endTime)}
                                    </Typography>
                                    <Typography variant="caption" className="duration-info">
                                        Duration: {formatTime(audioData[speaker.speakerId].duration)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }, [
        selectedTeamId,
        usersLoaded,
        usersLoading,
        getFilteredUsers,
        selections,
        handleSelect,
        handleRemove,
        audioData,
        currentPlayingIndex,
        isPlaying
    ]);

    const togglePlayPause = useCallback((index, audioData) => {
        if (currentPlayingIndex === index && isPlaying) {
            if (activeSource) {
                activeSource.stop();
                setActiveSource(null);
            }
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
        } else {
            playBase64Audio(index, audioData);
        }
    }, [currentPlayingIndex, isPlaying, activeSource]);

    const formatTime = useCallback((time) => {
        const seconds = Math.floor(time / 1000);
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }, []);

    const playBase64Audio = async (index, base64Data) => {
        try {
            if (activeSource) {
                activeSource.stop();
                setActiveSource(null);
            }

            const audioData = atob(base64Data);
            const arrayBuffer = new ArrayBuffer(audioData.length);
            const view = new Uint8Array(arrayBuffer);
            for (let i = 0; i < audioData.length; i++) {
                view[i] = audioData.charCodeAt(i);
            }

            if (!audioContextRef.current) {
                audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
            }

            const audioBuffer = await audioContextRef.current.decodeAudioData(arrayBuffer);
            const source = audioContextRef.current.createBufferSource();
            source.buffer = audioBuffer;
            source.connect(audioContextRef.current.destination);
            
            setActiveSource(source);
            
            source.onended = () => {
                setIsPlaying(false);
                setCurrentPlayingIndex(null);
                setActiveSource(null);
            };

            source.start(0);
            setIsPlaying(true);
            setCurrentPlayingIndex(index);
        } catch (error) {
            console.error('Error playing audio:', error);
            setIsPlaying(false);
            setCurrentPlayingIndex(null);
            setActiveSource(null);
        }
    };

    return (
        <ModalOverlay title="Edit Speaker Names" isOpen={isOpen} onClose={onClose}>
            {loading ? (
                <Box display="flex" justifyContent="center" p={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <Box className="modal-body transcript-editor">
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>
                    )}
                    
                    {/* Team Selection */}
                    <Box>
                        <Typography variant="subtitle1" className="section-title">
                            Team
                        </Typography>
                        <FormControl fullWidth>
                            <Select
                                value={selectedTeamId}
                                onChange={handleTeamChange}
                                displayEmpty
                                disabled={loadingInitial}
                            >
                                <MenuItem value="">
                                    <em>Select a team</em>
                                </MenuItem>
                                {teams.meetings.map((team) => (
                                    <MenuItem key={team.id} value={team.id}>
                                        {team.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>

                    {/* Team Members Section */}
                    <Box>
                        <Typography variant="subtitle1" className="section-title">
                            Team Members
                        </Typography>
                        {loadingInitial ? (
                            <Box display="flex" justifyContent="center" p={4}>
                                <CircularProgress />
                            </Box>
                        ) : (
                            speakerDetails.map(renderSpeakerSection)
                        )}
                        {loadingAudio && (
                            <Box mt={2} display="flex" alignItems="center" justifyContent="center">
                                <CircularProgress size={20} sx={{ mr: 1 }} />
                                <Typography variant="body2" color="text.secondary">
                                    Loading audio snippets...
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Button
                        variant="contained"
                        color="primary"
                        className="modal-save-button"
                        onClick={handleSave}
                        disabled={loading}
                    >
                        {loading ? 'Saving...' : 'Save changes'}
                    </Button>
                </Box>
            )}
        </ModalOverlay>
    );
};

export default TranscriptEditor;
