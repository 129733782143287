import React, {useState} from 'react';
import {Box, Typography, Snackbar, Alert, CircularProgress, LinearProgress} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import '../home/Meeting.css';
import './Upload.css';
import axios from "axios";
import config from "../../config";
import {trackProduct} from "../../common/googleanalytics";

const Upload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const handleOpenSnackbar = (message, severity = 'success') => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') return;
        setSnackbarOpen(false);
    };

    const generateFileName = (originalFileName) => {
        const now = new Date();
        const datePart = now.toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD
        const timePart = now.toISOString().slice(11, 19).replace(/:/g, ''); // HHMMSS
        const randomPart = Math.random().toString(36).substring(2, 7); // 5 random chars
        const fileExtension = originalFileName.split('.').pop();
        const sanitizedOriginalName = originalFileName.split('.')[0].replace(/[^a-zA-Z0-9]/g, '_');

        return `${datePart}_${timePart}_${randomPart}_${sanitizedOriginalName}.${fileExtension}`;
    };

    const getSignedUrl = async (file) => {
        try {
            const newFileName = generateFileName(file.name);
            const audioFileName = `audio_files/${newFileName}`;
            const response = await axios.post(`${config.backendUrlApiV1}/generate-signed-url/`, {
                file_name: audioFileName,
                content_type: file.type,
                file_size: file.size,
            });

            const signedUrl = response.data.url;
            return {signedUrl, newFileName};
        } catch (error) {
            console.error('Error getting signed URL:', error);
            handleOpenSnackbar('Failed to get upload URL. Please try again.', 'error');
            throw error;
        }
    };

    const uploadFileToGCS = async (file, signedUrl) => {
        try {
            const response = await axios.put(signedUrl, file, {
                headers: {
                    'Content-Type': file.type,
                },
                withCredentials: false,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setUploadProgress(percentCompleted);
                },
            });
            return true;
        } catch (error) {
            console.error('Error uploading file to GCS:', error);
            if (error.response) {
                console.error('Response data:', error.response.data);
                console.error('Response status:', error.response.status);
                console.error('Response headers:', error.response.headers);
            } else if (error.request) {
                console.error('No response received:', error.request);
            } else {
                console.error('Error setting up request:', error.message);
            }
            handleOpenSnackbar('Failed to upload file. Please try again.', 'error');
            return false;
        }
    };

    const notifyBackendForProcessing = async (fileName) => {
        try {
            await axios.post(`${config.backendUrlApiV1}/process-gcs-file/`, {
                file_name: `audio_files/${fileName}`,
            });
            return true;
        } catch (error) {
            console.error('Error notifying backend for processing:', error);
            handleOpenSnackbar('Error notifying backend for processing. Please check the file status.', 'warning');
            return false;
        }
    };

    const onUpload = async (file) => {
        setIsLoading(true); // Start loading
        try {
            if (file.size > 32 * 1024 * 1024) {
                if (file.size > config.maxFileSize) {
                    handleOpenSnackbar('File size exceeds the 2 GB limit', 'error');
                    return;
                }

                // Handle files larger than 32MB
                let signedUrl, newFileName;
                try {
                    ({signedUrl, newFileName} = await getSignedUrl(file));
                } catch (error) {
                    console.error('Failed to get signed URL:', error);
                    handleOpenSnackbar('Error uploading file. Please try again.', 'error');
                    return; // Stop further execution if getSignedUrl fails
                }

                try {
                    await uploadFileToGCS(file, signedUrl);
                } catch (error) {
                    console.error('Error uploading file to GCS:', error);
                    handleOpenSnackbar('Error uploading file. Please try again.', 'error');
                    return; // Stop further execution if uploadFileToGCS fails
                }

                try {
                    await notifyBackendForProcessing(newFileName);
                    handleOpenSnackbar('File is being processed. Check Home tab in 2 minutes to see your meeting analysis', 'success');
                } catch (error) {
                    console.error('Error notifying backend:', error);
                    handleOpenSnackbar('Error processing file. Please try again.', 'error');
                }

            } else {
                // Handle files smaller than or equal to 32MB
                const formData = new FormData();
                formData.append('file', file);
                handleOpenSnackbar('File is being processed', 'success');
                const uploadResponse = await axios.post(`${config.backendUrlApiV1}/upload-and-process/`, formData);
                const data = uploadResponse.data;
                if (uploadResponse.status === 200) {
                    handleOpenSnackbar(data.message, 'success');
                }
            }
            trackProduct.success('upload_file')
        } catch (error) {
            handleOpenSnackbar('An unexpected error occurred. Please try again.', 'error');
            trackProduct.error('upload_file_error')
            console.error('Error:', error);
        } finally {
            setIsLoading(false); // End loading
        }
    };

    const handleFileSelect = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > config.maxFileSize) {
                handleOpenSnackbar('File size exceeds the 2 GB limit', 'error');
                return;
            }
            setSelectedFile(file);
            onUpload(file);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            if (file.size > config.maxFileSize) {
                handleOpenSnackbar('File size exceeds the 2 GB limit', 'error');
                return;
            }
            setSelectedFile(file);
            onUpload(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    return (
        <Box className="curved-view upload-container">
            <Typography variant="h4" className="header-title">
                Upload an audio or video file to analyse meeting
            </Typography>
            <Typography variant="body1" className="header-subtitle">
                Depending on the size of the audio file, it will be processed in 2-5 minutes
            </Typography>
            <Box
                className={`dotted-box ${isLoading ? 'disabled' : ''}`}
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onClick={() => !isLoading && document.getElementById('fileInput').click()}
                style={{position: 'relative'}}
            >
                {isLoading ? (
                    <Box className="loading-overlay">
                        <CircularProgress className="loading-spinner"/>
                        <Typography variant="body2" style={{marginTop: '10px'}}>
                            Uploading: {uploadProgress.toFixed(2)}%
                        </Typography>
                        <LinearProgress
                            variant="determinate"
                            value={uploadProgress}
                            style={{width: '80%', marginTop: '10px'}}
                        />
                    </Box>
                ) : (
                    <>
                        <CloudUploadIcon className="upload-icon"/>
                        <Typography variant="body1" className="upload-text">
                            Drag and drop MP3, M4A, WAV or MP4 file here, or select files to upload.
                            Limit 1GB per file.
                        </Typography>
                    </>
                )}
                <input
                    id="fileInput"
                    type="file"
                    accept=".mp3,.m4a,.wav,.mp4"
                    style={{display: 'none'}}
                    onChange={handleFileSelect}
                />
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{width: '100%'}}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default Upload;
