import React, { useState, useCallback, useRef, useEffect } from 'react';
import { TextField } from '@mui/material';
import './UserSearchInput.css';

const UserSearchInput = ({ availableUsers, onUserSelect, placeholder = "Search users" }) => {
    const [inputValue, setInputValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const inputRef = useRef(null);



    // Filter users based on the search term, otherwise return all users
    const filterUsers = useCallback((searchTerm) => {
        if (!searchTerm) {
            // If no search term, return all available users up to a limit
            return availableUsers.slice(0, 10);
        }
        
        const lowerCaseSearch = searchTerm.toLowerCase();

        return availableUsers
            .map(user => ({
                ...user,
                score: calculateScore(user, lowerCaseSearch)
            }))
            .filter(user => user.score > 0)
            .sort((a, b) => b.score - a.score)
            .slice(0, 10); // Limit to top 10 results
    }, [availableUsers]);

    // Calculate relevance score for each user
    const calculateScore = (user, searchTerm) => {
        const nameLower = user.name.toLowerCase();
        const emailLower = user.email.toLowerCase();

        if (nameLower.startsWith(searchTerm)) return 100;
        if (emailLower.startsWith(searchTerm)) return 90;
        if (nameLower.includes(searchTerm)) return 80;
        if (emailLower.includes(searchTerm)) return 70;

        return 0;
    };

    // Handle input changes and update suggestions
    const handleInputChange = (event) => {
        const value = event.target.value;
        setInputValue(value);
        setSuggestions(filterUsers(value));
    };

    const handleSuggestionClick = (user) => {
        setInputValue('');
        setSuggestions([]);
        onUserSelect(user);
    };
    

    // Handle click outside to close suggestions
    const handleClickOutside = useCallback((event) => {
        if (inputRef.current && !inputRef.current.contains(event.target)) {
            setInputValue('');
            setSuggestions([]);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    // Show suggestions immediately when the input field is focused
    const handleInputFocus = () => {
        setSuggestions(filterUsers(inputValue));
    };

    return (
        <div className="user-search-root" ref={inputRef}>
            <TextField
                fullWidth
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                placeholder={placeholder}
                onFocus={handleInputFocus} // Open suggestions when focused
            />
            {suggestions.length > 0 && (
                <div className="suggestions-container">
                    {suggestions.map((user) => (
                        <div
                            key={user.id}
                            className="suggestion-item"
                            onClick={() => handleSuggestionClick(user)}
                        >
                            <div className="suggestion-avatar">
                                {user.name.charAt(0).toUpperCase()}
                            </div>
                            <div className="suggestion-text">
                                <span className="suggestion-name">{user.name}</span>
                                <span className="suggestion-email">{user.email}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default UserSearchInput;
