import React, { useState } from 'react';
import {
    Dialog, DialogTitle, DialogContent, IconButton, Typography, Divider,
    List, ListItem, ListItemIcon, ListItemText, Collapse, Tooltip, useTheme, Box, Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CategoryIcon from '@mui/icons-material/Category';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import StarIcon from '@mui/icons-material/Star';
import VerifiedIcon from '@mui/icons-material/Verified';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import InfoItem from './InfoItem';
import BarChartIcon from '@mui/icons-material/BarChart';

const SectionTitle = ({ icon, children }) => (
    <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        {icon}
        <span style={{ marginLeft: '8px' }}>{children}</span>
    </Typography>
);

const formatTime = (milliseconds) => {
    if (!milliseconds) return 'N/A';
    const seconds = milliseconds / 1000;
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};
const formatTimeInSeconds = (seconds) => {
    if (seconds == null || isNaN(seconds)) return 'N/A';
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

const TopicInfoCard = ({ topic, open, onClose, onJumpToTopic }) => {
    const [keyPointsExpanded, setKeyPointsExpanded] = useState(false);
    const [sentimentExpanded, setSentimentExpanded] = useState(false);
    const [metricsExpanded, setMetricsExpanded] = useState(false);

    const theme = useTheme();

    const handleExpandClick = (setter) => () => {
        setter(prev => !prev);
    };

    const startTime = formatTime(topic.startTime);
    const endTime = formatTime(topic.endTime);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            aria-labelledby="topic-info-dialog-title"
        >
            <DialogTitle id="topic-info-dialog-title" sx={{ m: 0, p: 2 }}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        {topic.name}
                    </Typography>
                    <Button
                        startIcon={<PlayArrowIcon />}
                        onClick={() => onJumpToTopic(topic.startTime)}
                        color="primary"
                        variant="outlined"
                        size="small"
                        sx={{ mr: 2 }}
                    >
                        Jump to Topic
                    </Button>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        sx={{
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent dividers>
                <InfoItem label="Category" value={topic.category} icon={<CategoryIcon color="primary" fontSize="small" />} />
                <InfoItem label="Time Range" value={`${startTime} - ${endTime}`} icon={<AccessTimeIcon color="primary" fontSize="small" />} />

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<SummarizeIcon color="primary" />}>Summary</SectionTitle>
                <Typography variant="body2" paragraph color="text.secondary">
                    {topic.summary || "No summary available."}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<ListAltIcon color="primary" />}>
                    Key Points
                    <Tooltip title={keyPointsExpanded ? "Hide key points" : "Show key points"}>
                        <IconButton
                            onClick={handleExpandClick(setKeyPointsExpanded)}
                            aria-expanded={keyPointsExpanded}
                            aria-label="toggle key points"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: keyPointsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={keyPointsExpanded} timeout="auto" unmountOnExit>
                    <List dense>
                        {topic.keyPoints && topic.keyPoints.length > 0 ? (
                            topic.keyPoints.map((point, index) => (
                                <ListItem key={index} disablePadding>
                                    <ListItemIcon sx={{ minWidth: 20 }}>
                                        <FiberManualRecordIcon sx={{ fontSize: '0.5rem' }} color="primary" />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={point}
                                        primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                    />
                                </ListItem>
                            ))
                        ) : (
                            <ListItem>
                                <ListItemText
                                    primary="No key points available"
                                    primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                />
                            </ListItem>
                        )}
                    </List>
                </Collapse>

                <Divider sx={{ my: 2 }} />

                <SectionTitle icon={<SentimentSatisfiedAltIcon color="primary" />}>
                    Sentiment Analysis
                    <Tooltip title={sentimentExpanded ? "Hide sentiment analysis" : "Show sentiment analysis"}>
                        <IconButton
                            onClick={handleExpandClick(setSentimentExpanded)}
                            aria-expanded={sentimentExpanded}
                            aria-label="toggle sentiment analysis"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: sentimentExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={sentimentExpanded} timeout="auto" unmountOnExit>
                    <InfoItem label="Sentiment" value={topic.sentiment} icon={<SentimentSatisfiedAltIcon color="primary" fontSize="small" />} />
                    <InfoItem label="Sentiment Reasoning" value={topic.sentimentReasoning} icon={<SentimentSatisfiedAltIcon color="primary" fontSize="small" />} />
                </Collapse>

                <Divider sx={{ my: 2 }} />


                <SectionTitle icon={<BarChartIcon color="primary" />}>
                    Metrics
                    <Tooltip title={metricsExpanded ? "Hide metrics" : "Show metrics"}>
                        <IconButton
                            onClick={handleExpandClick(setMetricsExpanded)}
                            aria-expanded={metricsExpanded}
                            aria-label="toggle metrics"
                            size="small"
                            sx={{ ml: 1 }}
                        >
                            <ExpandMoreIcon
                                sx={{
                                    transform: metricsExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </SectionTitle>

                <Collapse in={metricsExpanded} timeout="auto" unmountOnExit>
                    <Box sx={{ mt: 1 }}>
                        <InfoItem
                            label="Importance Score"
                            value={topic.importanceScore ? topic.importanceScore.toFixed(2) : 'N/A'}
                            icon={<StarIcon color="primary" fontSize="small" />}
                        />
                        <InfoItem
                            label="Confidence Score"
                            value={topic.confidenceScore ? topic.confidenceScore.toFixed(2) : 'N/A'}
                            icon={<VerifiedIcon color="primary" fontSize="small" />}
                        />
                    </Box>
                </Collapse>
            </DialogContent>
        </Dialog>
    );
};

export default TopicInfoCard;
