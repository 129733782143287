import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import {
    Box,
    Tabs,
    Tab,
    Grid,
} from '@mui/material';
import config from '../../config';
import './css/Analytics.css';
import AnalyticsFilters from "./AnalyticsFilters";
import AnalyticsIndicators from "./AnalyticsIndicators";
import AnalyticsIndicatorsChart from "./AnalyticsIndicatorsChart";
import AnalyticsSpeakerRatioChart from "./AnalyticsSpeakerRatioChart";

const Analytics = () => {
    const [tabIndex, setTabIndex] = useState(0);
    const [filters, setFilters] = useState({
        dateFilter: 'last30Days',
        teamFilter: '',
    });
    const [historicalData, setHistoricalData] = useState([]);
    const [averageMetrics, setAverageMetrics] = useState({});

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    const fetchData = useCallback(() => {
        if (!filters.teamFilter) return;
    
        const params = new URLSearchParams();
        params.append('date', filters.dateFilter);
        params.append('teams', filters.teamFilter);
    
        axios.get(`${config.backendUrlApiV1}/analytics-metrics/?${params}`)
            .then(response => {
                console.log('Analytics response:', response.data); // Debug
                setHistoricalData(response.data.historicalMetrics || []);
                setAverageMetrics(response.data.historicalMetricsAverage || {});
            })
            .catch(error => {
                console.error('Analytics error:', error);
                setHistoricalData([]);
                setAverageMetrics({});
            });
    }, [filters]);
    
    useEffect(() => {
        if (filters.teamFilter) {
            fetchData();
        }
    }, [fetchData, filters.teamFilter]);

    const handleFilterChange = (newFilters) => {
        setFilters(newFilters);
    };

    return (
        <Box className="tab-curved-view">
            <Box className="tab-header">
                <Tabs value={tabIndex} onChange={handleTabChange} aria-label="Analytics Tabs">
                    <Tab label="Overview"/>
                    {/* Add more tabs as needed */}
                </Tabs>
            </Box>
            <Box className="tab-panel" hidden={tabIndex !== 0}>
                <AnalyticsFilters
                    filters={filters}
                    onFilterChange={handleFilterChange}
                />
                <Box className="overview-content">
                    <Grid container spacing={5}>
                        <Grid item xs={12}>
                            <AnalyticsSpeakerRatioChart historicalData={historicalData}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AnalyticsIndicators data={averageMetrics}/>
                        </Grid>
                        <Grid item xs={12}>
                            <AnalyticsIndicatorsChart historicalData={historicalData}/>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            {/* Add more tab panels as needed */}
        </Box>
    );
};

export default Analytics;
