import React from 'react';
import {
    Card,
    CardContent,
    Typography,
    IconButton,
    Box,
    Divider,
    Chip,
    Tooltip,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import './css/TeamCard.css';
import config from '../../config';

const TeamCard = ({ team, onClick, onDeleteClick }) => {
    const handleDeleteClick = (e) => {
        e.stopPropagation(); // Prevent card click event when delete icon is clicked
        onDeleteClick();
    };

    return (
        <Card
            className="team-card"
            onClick={onClick}
            sx={{
                borderRadius: 2,
                boxShadow: 2,
                cursor: 'pointer', // Change cursor to pointer for hover
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'scale(1.02)', // Slightly enlarge card on hover
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)', // Add subtle shadow
                },
            }}
        >
            <CardContent>
                <Box
                    className="team-card-header"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Typography
                        variant="h5"
                        component="div"
                        fontWeight="bold"
                        sx={{ color: config.elevaide_orange }}
                    >
                        {team.name}
                    </Typography>
                    <Tooltip title="Delete Team">
                        <IconButton
                            onClick={handleDeleteClick}
                            size="small"
                            aria-label="Delete Team"
                        >
                            <DeleteIcon sx={{ color: 'grey.600' }} />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                    {team.company?.name || 'No Company'}
                </Typography>
                <Divider className="team-card-divider" sx={{ margin: '8px 0' }} />

                <Box sx={{ marginBottom: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', color: 'grey.700', marginBottom: '8px' }}
                    >
                        Manager
                    </Typography>
                    <Box className="team-card-manager">
                        {team.manager ? (
                            <Chip
                                label={team.manager.name}
                                size="small"
                                sx={{
                                    color: 'black',
                                    margin: '2px',
                                }}
                            />
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No Manager Assigned
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box sx={{ marginBottom: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', color: 'grey.700', marginBottom: '8px' }}
                    >
                        Coaches
                    </Typography>
                    <Box className="team-card-coaches">
                        {team.coaches && team.coaches.length > 0 ? (
                            team.coaches.map((coach) => (
                                <Chip
                                    key={coach.id}
                                    label={coach.name}
                                    size="small"
                                    sx={{
                                        color: 'black',
                                        margin: '2px',
                                    }}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No Coaches Assigned
                            </Typography>
                        )}
                    </Box>
                </Box>

                <Box sx={{ marginBottom: 2 }}>
                    <Typography
                        variant="body2"
                        sx={{ fontWeight: 'bold', color: 'grey.700', marginBottom: '8px' }}
                    >
                        Members
                    </Typography>
                    <Box
                        className="team-card-members"
                        display="flex"
                        flexWrap="wrap"
                        gap={1}
                    >
                        {team.members && team.members.length > 0 ? (
                            team.members.map((member) => (
                                <Chip
                                    key={member.id}
                                    label={
                                        member.name.length > 15
                                            ? `${member.name.slice(0, 12)}...`
                                            : member.name
                                    }
                                    size="small"
                                    sx={{
                                        color: 'black',
                                        margin: '2px',
                                    }}
                                    title={member.name}
                                />
                            ))
                        ) : (
                            <Typography variant="body2" color="text.secondary">
                                No Members Assigned
                            </Typography>
                        )}
                    </Box>
                </Box>
            </CardContent>
        </Card>
    );
};

export default TeamCard;
