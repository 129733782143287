import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Card,
    CardContent,
    Typography,
    CircularProgress,
    IconButton,
    useMediaQuery,
    useTheme,
    Tabs,
    Tab,
    Grid,
    Paper,
    Button,
    createTheme,
    Chip
} from '@mui/material';
import {
    ChevronLeft,
    ChevronRight,
    CalendarToday,
    Schedule,
    Timer,
    Close,
    OpenInNew,
} from '@mui/icons-material';
import axios from "axios";
import config from "../config";
import { Edit } from '@mui/icons-material';
import TranscriptEditor from "./home/transcript/TranscriptEditor";
import { RefreshProvider} from "./../auth/RefreshProvider";
import theme from './../theme';
import TeamFilterDropdown from './../components/TeamFilterDropdown';

const numberOfRecordsPerPage = 25;

const MeetingCard = ({ meeting, isSelected, onClick, isDisabled }) => {
    const theme = useTheme();

    const renderDetail = (Icon, label) => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
            <Icon sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
            <Typography variant="caption" color="text.secondary">
                {label}
            </Typography>
        </Box>
    );

    return (
        <Card
            onClick={() => !isDisabled && onClick(meeting)}
            sx={{
                borderRadius: 2,
                boxShadow: 2,
                cursor: isDisabled ? 'default' : 'pointer',
                transition: 'transform 0.2s, box-shadow 0.2s',
                opacity: isDisabled ? 0.7 : 1,
                border: '1px solid rgba(0, 0, 0, 0.08)',
                '&:hover': !isDisabled ? {
                    transform: 'scale(1.02)',
                    boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                    '& .meeting-title': {
                        color: theme.palette.primary.main,
                        fontWeight: 600
                    }
                } : {},
                backgroundColor: '#FFFFFF'
            }}
        >
            <CardContent sx={{ p: 2, '&:last-child': { pb: 2 } }}>
                <Box sx={{ mb: 1 }}>
                <Typography
                    className="meeting-title"
                    variant="h7"
                    component="div"
                    sx={{ 
                        color: isSelected ? theme.palette.primary.main : 'inherit',
                        mb: 1,
                        fontWeight: isSelected ? 600 : 'normal',
                        '&:hover': {
                            color: theme.palette.primary.main,
                            fontWeight: 600
                        }
                    }}
                >
                    {meeting.name}
                </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {meeting.team || 'No Team'}
                    </Typography>
                </Box>

                <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)', gap: 1 }}>
                    {renderDetail(CalendarToday, meeting.date)}
                    {renderDetail(Schedule, meeting.time)}
                    {renderDetail(Timer, meeting.duration)}
                </Box>
            </CardContent>
        </Card>
    );
};

const TranscriptView = ({ transcript, loading }) => {
    const theme = useTheme();

    if (loading) {
        return (
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '100%' 
            }}>
                <CircularProgress />
            </Box>
        );
    }

    return (
        <Paper
            elevation={0}
            sx={{
                height: '100%',
                backgroundColor: '#FFFFFF',
                borderRadius: 1,
                overflow: 'hidden',
            }}
        >
            {/* Header Section */}
            <Box 
                sx={{ 
                    p: 2, 
                    backgroundColor: '#FFFFFF',
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                }}
            >
                {/* <ArticleOutlined sx={{ color: config.elevaide_orange, fontSize: '1.5rem' }} />
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    Transcript
                </Typography> */}
            </Box>

            {/* Transcript Content */}
            <Box 
                sx={{
                    height: 'calc(100% - 60px)',
                    overflow: 'auto',
                    backgroundColor: '#FFFFFF',
                    '&::-webkit-scrollbar': {
                        width: '6px',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#F5F5F5',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: '#E0E0E0',
                    },
                }}
            >
                {transcript?.utterances ? (
                    transcript.utterances.map((utterance, index) => (
                        <Box
                            key={index}
                            sx={{
                                py: 1.5,
                                px: 2,
                                '&:hover': {
                                    backgroundColor: '#FAFAFA',
                                },
                            }}
                        >
                            <Typography 
                                variant="subtitle2" 
                                sx={{ 
                                    color: theme.palette.text.primary,
                                    fontWeight: 600,
                                    fontSize: '0.875rem',
                                    mb: 0.5,
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                }}
                            >
                                {utterance.speakerName}
                                <Typography 
                                    variant="caption" 
                                    sx={{ color: theme.palette.text.secondary }}
                                >
                                    • {utterance.startTimeFormatted}
                                </Typography>
                            </Typography>
                            <Typography variant="body2">
                                {utterance.text}
                            </Typography>
                        </Box>
                    ))
                ) : (
                    <Box sx={{ p: 3, textAlign: 'center' }}>
                        <Typography color="text.secondary">
                            No transcript available
                        </Typography>
                    </Box>
                )}
            </Box>
        </Paper>
    );
};
    
  
const PaginationControls = ({ currentPage, totalPages, totalRecords, onPageChange }) => (
    <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        py: 2,
        px: 1,
        borderTop: 1,
        borderColor: 'divider',
        mt: 2
    }}>
        <Typography variant="body2" color="text.secondary">
            {`${(currentPage - 1) * numberOfRecordsPerPage + 1}-${Math.min(currentPage * numberOfRecordsPerPage, totalRecords)} of ${totalRecords}`}
        </Typography>
        <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton 
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 1}
                size="small"
                sx={{ 
                    backgroundColor: theme => theme.palette.grey[100],
                    '&:hover': {
                        backgroundColor: theme => theme.palette.grey[200],
                    }
                }}
            >
                <ChevronLeft />
            </IconButton>
            <IconButton
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
                size="small"
                sx={{ 
                    backgroundColor: theme => theme.palette.grey[100],
                    '&:hover': {
                        backgroundColor: theme => theme.palette.grey[200],
                    }
                }}
            >
                <ChevronRight />
            </IconButton>
        </Box>
    </Box>
);



const Meetings = () => {
    const [meetingSets, setMeetingSets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [paginationStates, setPaginationStates] = useState({});
    const pollingInterval = useRef(null);
    const [selectedMeeting, setSelectedMeeting] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isEditorOpen, setIsEditorOpen] = useState(false);
    const [teamFilter, setTeamFilter] = useState(null);
    
    const POLLING_INTERVAL = 20000;
    const MAX_POLLING_TIME = 600000;
    const navigate = useNavigate();

    const [details, setDetails] = useState(null);
    const [detailsLoading, setDetailsLoading] = useState(false);

    const fetchMeetingDetails = async (meetingId) => {
        if (!meetingId) return;
    
        setDetailsLoading(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-metrics/`, {
                params: {
                    selectedMeetingId: meetingId,
                    getTranscript: true,
                    getSurvey: false,
                    getSummary: true,
                },
            });
    
            const data = response.data;
            setDetails({
                summary: data.summary,
                transcript: data.transcript,
                metaData: data.metaData,
            });
    
            // Update meetingSets with the new data
            setMeetingSets((prevMeetingSets) =>
                prevMeetingSets.map((set) => ({
                    ...set,
                    meetings: set.meetings.map((week) => ({
                        ...week,
                        items: week.items.map((meeting) =>
                            meeting.id === meetingId ? { ...meeting, ...data.metaData } : meeting
                        ),
                    })),
                }))
            );
        } catch (error) {
            console.error('Error fetching meeting details:', error);
        } finally {
            setDetailsLoading(false);
        }
    };
    

    const fetchMeetingsForActiveTab = useCallback(async (page) => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return;

        setLoading(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/meeting/weekly_view/`, {
                params: {
                    userId: currentSet.userId,
                    page: page
                }
            });

            const {data} = response.data;
            if (data?.length > 0) {
                setMeetingSets(prev => prev.map((set, index) =>
                    index === activeTab ? {
                        ...set,
                        meetings: data[0].meetings,
                        totalPages: data[0].totalPages,
                        totalRecords: data[0].totalRecords
                    } : set
                ));
            }
        } catch (error) {
            console.error('Error fetching meetings:', error);
        } finally {
            setLoading(false);
        }
    }, [activeTab, meetingSets]);

    const checkMeetingStatus = useCallback(async () => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet?.meetings) return;

        const unreadyMeetings = currentSet.meetings
            .flatMap(week => week.items)
            .filter(meeting => !meeting.ready)
            .map(meeting => meeting.id);

        if (unreadyMeetings.length === 0) {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
            }
            return;
        }

        try {
            const response = await axios.get(`${config.backendUrlApiV1}/meeting/check_status/`, {
                params: {
                    meeting_ids: unreadyMeetings.join(',')
                }
            });

            const {completedMeetings} = response.data;
            if (completedMeetings?.length > 0) {
                setMeetingSets(prev => prev.map((set, index) =>
                    index === activeTab ? {
                        ...set,
                        meetings: set.meetings.map(week => ({
                            ...week,
                            items: week.items.map(meeting => ({
                                ...meeting,
                                ready: meeting.ready || completedMeetings.includes(meeting.id)
                            }))
                        }))
                    } : set
                ));
            }
        } catch (error) {
            console.error('Error checking meeting status:', error);
        }
    }, [meetingSets, activeTab]);

    const startPolling = useCallback(() => {
        if (pollingInterval.current) {
            clearInterval(pollingInterval.current);
        }

        const startTime = Date.now();
        checkMeetingStatus();

        pollingInterval.current = setInterval(() => {
            if (Date.now() - startTime > MAX_POLLING_TIME) {
                clearInterval(pollingInterval.current);
                pollingInterval.current = null;
                return;
            }
            checkMeetingStatus();
        }, POLLING_INTERVAL);
    }, [checkMeetingStatus]);

    useEffect(() => {
        const initializeMeetings = async () => {
            setLoading(true);
            try {
                const response = await axios.get(`${config.backendUrlApiV1}/meeting/weekly_view/`);
                const {data} = response.data;
                if (data?.length > 0) {
                    setMeetingSets(data);
                    const initialPaginationStates = {};
                    data.forEach(set => {
                        initialPaginationStates[set.userId] = {
                            currentPage: 1,
                            totalPages: set.totalPages,
                            totalRecords: set.totalRecords
                        };
                    });
                    setPaginationStates(initialPaginationStates);

                    // Auto-select the first ready meeting
                    const firstSet = data[0];
                    if (firstSet.meetings?.length > 0) {
                        const firstWeek = firstSet.meetings[0];
                        const firstReadyMeeting = firstWeek.items.find(meeting => meeting.ready);
                        if (firstReadyMeeting) {
                            setSelectedMeeting(firstReadyMeeting);
                            setDrawerOpen(true);
                            fetchMeetingDetails(firstReadyMeeting.id);
                        }
                    }
                }
            } catch (error) {
                console.error('Error initializing meetings:', error);
            } finally {
                setLoading(false);
            }
        };

        initializeMeetings();
    }, []);


    useEffect(() => {
        startPolling();
        return () => {
            if (pollingInterval.current) {
                clearInterval(pollingInterval.current);
            }
        };
    }, [meetingSets, activeTab, startPolling]);

    const handleEditorOpen = () => {
        setIsEditorOpen(true);
    };

    const handleEditorClose = () => {
        setIsEditorOpen(false);
    };

    const handleEditorSave = (updatedDetails) => {
        if (selectedMeeting?.id) {
            setMeetingSets((prevMeetingSets) =>
                prevMeetingSets.map((set) => ({
                    ...set,
                    meetings: set.meetings.map((week) => ({
                        ...week,
                        items: week.items.map((meeting) =>
                            meeting.id === selectedMeeting.id
                                ? { ...meeting, ...updatedDetails }
                                : meeting
                        ),
                    })),
                }))
            );
            setSelectedMeeting((prev) => ({ ...prev, ...updatedDetails })); // Update selectedMeeting state
            setIsEditorOpen(false);
        }
    };
    
    
    

    // Modify handleMeetingClick to fetch details
    const handleMeetingClick = (meeting) => {
        if (!meeting.ready) return;
        setSelectedMeeting(meeting);
        setDrawerOpen(true);
        fetchMeetingDetails(meeting.id);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setSelectedMeeting(null);
        setDetails(null);  // Clear details when closing
    };

    const handleViewDetails = (meetingId) => {
        navigate(`/product/meeting/${meetingId}`);
    };

    const handlePageChange = (newPage) => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return;

        const currentPagination = paginationStates[currentSet.userId];
        if (newPage >= 1 && newPage <= currentPagination.totalPages) {
            setPaginationStates(prev => ({
                ...prev,
                [currentSet.userId]: {
                    ...prev[currentSet.userId],
                    currentPage: newPage
                }
            }));
            fetchMeetingsForActiveTab(newPage);
        }
    };

    const getCurrentPagination = useCallback(() => {
        const currentSet = meetingSets[activeTab];
        if (!currentSet) return {currentPage: 1, totalPages: 1, totalRecords: 0};

        return paginationStates[currentSet.userId] || {
            currentPage: 1,
            totalPages: 1,
            totalRecords: 0
        };
    }, [activeTab, meetingSets, paginationStates]);


    const renderContent = () => {
        if (loading) {
            return (
                <Box sx={{ 
                    display: 'flex', 
                    justifyContent: 'center', 
                    alignItems: 'center',
                    minHeight: '200px'
                }}>
                    <CircularProgress />
                </Box>
            );
        }
    
        const currentMeetingSet = meetingSets[activeTab];
        if (!currentMeetingSet?.meetings?.length) {
            return (
                <Paper 
                    elevation={0}
                    sx={{ 
                        p: 4, 
                        textAlign: 'center',
                        backgroundColor: theme => theme.palette.grey[50],
                        border: 1,
                        borderColor: 'divider',
                        borderRadius: 2
                    }}
                >
                    <Typography color="text.secondary">
                        No meetings to display
                    </Typography>
                </Paper>
            );
        }
    
        
        const filteredMeetings = currentMeetingSet.meetings.map(week => ({
            ...week,
            items: week.items.filter(meeting => {
                if (teamFilter === null) return true;  // All meetings
                if (teamFilter === undefined) {
                    return !meeting.team;  // Only meetings with no team
                }
                return meeting.team === teamFilter;  // Specific team
            })
        })).filter(week => week.items.length > 0);
        
    
        return (
            <Box>
                <Box sx={{ mb: 3, mt: 2, mx: 0.5 }}>
                    <TeamFilterDropdown 
                        meetings={currentMeetingSet.meetings}
                        onFilterChange={setTeamFilter}
                        currentFilter={teamFilter}
                    />
                </Box>
    
                <Box sx={{ 
                    display: 'flex', 
                    flexDirection: 'column', 
                    gap: 1 
                }}>
                    {filteredMeetings.flatMap(week => week.items).map((meeting) => (
                        <MeetingCard
                            key={meeting.id}
                            meeting={meeting}
                            onClick={handleMeetingClick}
                            isDisabled={!meeting.ready}
                            isSelected={selectedMeeting?.id === meeting.id}
                        />
                    ))}
                </Box>
                <PaginationControls
                    currentPage={getCurrentPagination().currentPage}
                    totalPages={getCurrentPagination().totalPages}
                    totalRecords={getCurrentPagination().totalRecords}
                    onPageChange={handlePageChange}
                />
            </Box>
        );
    };



    const MainContent = () => (
        <Box 
            sx={{ 
                width: { 
                    xs: '100%',
                    sm: '400px',
                    md: '450px'
                },
                minWidth: {
                    xs: '100%',
                    sm: '400px',
                    md: '450px'
                },
                mx: { xs: 2, sm: 4 },
                my: 3,
                backgroundColor: 'background.default',
                // borderRadius: 2,
                flexShrink: 0,
            }}
        >
            {meetingSets.length > 1 && (
                <Tabs
                    value={activeTab}
                    onChange={(_, newValue) => setActiveTab(newValue)}
                    sx={{ 
                        mb: 4,
                        borderBottom: 1,
                        borderColor: 'divider',
                        '& .MuiTab-root': {
                            textTransform: 'none',
                            fontWeight: 500,
                            minWidth: 120,
                            color: 'text.secondary',
                            '&.Mui-selected': {
                                color: 'primary.main',
                            }
                        }
                    }}
                    variant="scrollable"
                    scrollButtons="auto"
                >
                    {meetingSets.map((set) => (
                        <Tab 
                            key={set.userId}
                            label={set.tabName}
                        />
                    ))}
                </Tabs>
            )}
            {renderContent()}
        </Box>
    );

    return (
        <Box 
            sx={{ 
                display: 'flex',
                width: '100%',
                backgroundColor: 'background.default',
                position: 'relative',
                gap: 0,
                justifyContent: 'flex-start',
                ml: { xs: 0, sm: 0 },
                pr: { xs: 0, md: 8 },
            }}
        >
            <Box sx={{ 
                display: 'flex',
                justifyContent: 'flex-start',
            }}>
                <MainContent />
            </Box>
    
            {drawerOpen && (
                <Box sx={{
                    flex: 1,
                    height: `calc(100vh - 64px)`,
                    mt: '64px',
                    position: 'sticky',
                    top: 0,
                    display: { xs: 'none', md: 'flex' },
                    flexDirection: 'column',
                    // borderLeft: '1px solid',
                    borderColor: 'divider',
                    flexShrink: 0,
                }}>
                    {/* Details section on top */}
                    <Box sx={{
                        height: '30%',
                        backgroundColor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        // borderBottom: '1px solid',
                        borderColor: 'divider',
                    }}>
                        <Box sx={{ 
                            p: 2,
                            // borderBottom: '1px solid',
                            borderColor: 'divider',
                            backgroundColor: 'background.default',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between'
                        }}>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                {/* <Typography variant="h5" sx={{ fontWeight: 600, color: theme.palette.primary.main }}> */}
                                <Typography variant="h5">
                                    {selectedMeeting.name}
                                </Typography>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleViewDetails(selectedMeeting?.id)}
                                    endIcon={<OpenInNew sx={{ fontSize: '0.875rem' }} />}
                                    sx={{
                                        height: '32px',
                                        padding: '0 6px',
                                        color: '#4A4A4A',
                                        // borderColor: "#D6D6D6", // Updated color
                                        borderColor: config.elevaide_orange, // Updated color
                                        // borderColor: config.elevaide_orange, // Updated color
                                        // border: '1px solid #D93025',
                                        borderRadius: '12px',
                                        fontSize: '0.750rem',
                                        fontWeight: 400,
                                        textTransform: 'none',
                                        '&:hover': {
                                            // backgroundColor: 'rgba(217, 48, 37, 0.1)', // Optional: Add hover background effect
                                            borderColor: config.elevaide_orange, // Retain the border color on hover
                                            // borderColor: theme.palette.primary.main, // Retain the border color on hover
                                        },
                                        '&:active': {
                                            transform: 'scale(0.98)',
                                        },
                                        '&:focus-visible': {
                                            outline: 'none',
                                            boxShadow: `0 0 0 3px rgba(217, 48, 37, 0.2)`,
                                        },
                                    }}
                                >
                                    View Insights
                                </Button>

                            </Box>
                            <IconButton onClick={handleDrawerClose} size="small">
                                <Close />
                            </IconButton>
                        </Box>
                        
                        <Box sx={{ p: 2 }}>
                            {/* <Typography variant="subtitle1" sx={{ mb: 1.5, fontWeight: 500 }}>
                                {selectedMeeting.name}
                            </Typography> */}
                            
                            <Grid container spacing={2}>
                                {/* Meeting metadata */}
                                <Grid item xs={6}>
                                    <Box sx={{ 
                                        p: 1,
                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                        borderRadius: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.06)'
                                    }}>
                                        <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mb: 0.5 }}>
                                            Meeting Info
                                        </Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                            <CalendarToday sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                            <Typography variant="body2">{selectedMeeting.date}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 0.5 }}>
                                            <Schedule sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                            <Typography variant="body2">{selectedMeeting.time}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Timer sx={{ fontSize: '0.875rem', color: 'text.secondary' }} />
                                            <Typography variant="body2">{selectedMeeting.duration}</Typography>
                                        </Box>
                                    </Box>
                                </Grid>
    
                                {/* Team and participants */}
                                <Grid item xs={6}>
                                    <Box sx={{ 
                                        p: 1,
                                        backgroundColor: 'rgba(0, 0, 0, 0.02)',
                                        borderRadius: 1,
                                        border: '1px solid rgba(0, 0, 0, 0.06)'
                                    }}>
                                        <Box sx={{ 
                                            display: 'flex', 
                                            justifyContent: 'space-between', 
                                            alignItems: 'center', 
                                            mb: 0.5
                                        }}>
                                        </Box>
                                        <Typography variant="caption" color="text.secondary">
                                                Team
                                        </Typography>
                                        <IconButton 
                                            size="medium" 
                                            onClick={handleEditorOpen}
                                            sx={{ p: 0.5 }}
                                        >
                                            <Edit sx={{ fontSize: '0.875rem' }} />
                                        </IconButton>
                                        <Typography variant="body2" sx={{ mb: 0.5 }}>
                                            {details?.metaData?.meetingTeam?.name || 'No team specified'}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                                Participants
                                            </Typography>
                                        {details?.metaData?.meetingParticipants && (
                                            <Box sx={{ display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                                                {details.metaData.meetingParticipants.slice(0, 3).map((participant, index) => (
                                                    <Chip
                                                        key={index}
                                                        label={participant.name}
                                                        size="small"
                                                        sx={{ 
                                                            height: '20px',
                                                            '& .MuiChip-label': {
                                                                fontSize: '0.75rem',
                                                                px: 1
                                                            }
                                                        }}
                                                    />
                                                ))}
                                                {details.metaData.meetingParticipants.length > 3 && (
                                                    <Chip
                                                        label={`+${details.metaData.meetingParticipants.length - 3}`}
                                                        size="small"
                                                        sx={{ 
                                                            height: '20px',
                                                            '& .MuiChip-label': {
                                                                fontSize: '0.75rem',
                                                                px: 1
                                                            }
                                                        }}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
    
                    {/* Transcript section on bottom */}
                    <Box 
                        sx={{
                            position: 'sticky',
                            top: 0,
                            height: 'calc(100vh - 64px)', // Adjust based on your header height
                            overflow: 'hidden',          // Prevent scrolling the entire container
                            backgroundColor: 'background.paper',
                            // borderLeft: '1px solid',
                            borderColor: 'divider',
                        }}
                    >
                        <TranscriptView transcript={details?.transcript} loading={detailsLoading} />
                    </Box>

                </Box>
            )}
    
            {details && (
                <RefreshProvider>
                    <TranscriptEditor
                        isOpen={isEditorOpen}
                        onClose={handleEditorClose}
                        onSave={handleEditorSave}
                        transcript={{
                            metaData: {
                                transcriptId: details.metaData.transcriptId,
                                meetingId: selectedMeeting.id,
                                ...details.metaData
                            },
                            ...details.transcript
                        }}
                    />
                </RefreshProvider>
            )}
        </Box>
    );
};

export default Meetings;
