import * as React from 'react';
import { useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthButtons from './AuthButtons';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import ElevaideLogo from '../images/logos/Orange/Wordmark.png';

const theme = createTheme({
    palette: {
        primary: { main: '#FF5733' },
        background: { default: '#F9F9F9', paper: '#FFFFFF' },
        text: {
            primary: 'rgba(0, 0, 0, 0.85)', // Softer black for headings
            secondary: 'rgba(0, 0, 0, 0.6)', // Muted gray for secondary text
        },
    },
    shadows: ['none', '0px 4px 16px rgba(0, 0, 0, 0.1)'],
});

export default function SignIn() {
    const { user, error, isLoading } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && user?.isAuthenticated) {
            navigate('/product', { replace: true });
        }
    }, [user, isLoading, navigate]);

    if (isLoading) {
        return (
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
                sx={{
                    background: 'linear-gradient(180deg, #FAFAFA 0%, #F5F5F5 100%)',
                }}
            >
                <CircularProgress sx={{ color: theme.palette.primary.main }} />
            </Box>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    background: 'linear-gradient(180deg, #FAFAFA 0%, #F5F5F5 100%)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    px: 2,
                }}
            >
                <Container
                    component="main"
                    sx={{
                        maxWidth: '400px !important',
                        width: '100%',
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '16px',
                        boxShadow: theme.shadows[1],
                        textAlign: 'center',
                        py: '40px',
                        px: '30px',
                    }}
                >
                    <CssBaseline />
                    <Box
                        sx={{
                            mb: '25px',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <img
                            src={ElevaideLogo}
                            alt="Elevaide"
                            style={{ width: '130px', height: 'auto' }}
                        />
                    </Box>
                    <Typography
                        variant="h1"
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: '24px',
                            fontWeight: 700,
                            mb: '10px',
                        }}
                    >
                        Welcome back!
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: theme.palette.text.secondary,
                            fontSize: '16px',
                            mb: '30px',
                        }}
                    >
                        Access your account
                    </Typography>
                    {error && (
                        <Box
                            sx={{
                                backgroundColor: 'rgba(234, 67, 53, 0.1)',
                                borderRadius: '8px',
                                p: 2,
                                mb: '20px',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{ color: theme.palette.primary.main }}
                            >
                                {error}
                            </Typography>
                        </Box>
                    )}
                    <AuthButtons />
                </Container>
            </Box>
        </ThemeProvider>
    );
}
