import React from 'react';
import { RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, Radar, ResponsiveContainer, Legend } from 'recharts';
import { Box, Typography } from '@mui/material';

const IndicatorsRadarChart = ({ data, view, allData }) => {
    if (!data || data.length === 0) {
        return (
            <Box sx={{ height: 300, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography>No valid indicator data available</Typography>
            </Box>
        );
    }

    // Transform data to include both team and individual scores separately
    const transformedData = data.map(item => {
        const teamScore = allData.find(teamItem => teamItem.indicator === item.indicator)?.score || 0;
        return {
            indicator: item.indicator,
            individual: item.score,
            team: teamScore
        };
    });

    return (
        <ResponsiveContainer width="100%" height={300}>
            <RadarChart 
                cx="50%" 
                cy="50%" 
                outerRadius="80%" 
                data={transformedData}
                style={{ background: 'transparent' }}
            >
                <PolarGrid stroke="#e0e0e0" />
                <PolarAngleAxis dataKey="indicator" />
                <PolarRadiusAxis angle={30} domain={[0, 100]} />
                <Radar
                    name="Team"
                    dataKey="team"
                    stroke="#4ECDC4"
                    fill="#4ECDC4"
                    fillOpacity={0.15}
                    strokeWidth={2}
                />
                {view !== 'all' && (
                    <Radar
                        name={view}
                        dataKey="individual"
                        stroke="#FF6B6B"
                        fill="#FF6B6B"
                        fillOpacity={0.15}
                        strokeWidth={2}
                    />
                )}
                <Legend />
            </RadarChart>
        </ResponsiveContainer>
    );
};

export default IndicatorsRadarChart;
