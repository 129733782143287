// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  // palette: {
    // primary: {
    //   main: '#D93025',
    // },
  // },
  typography: {
    fontFamily: '"Inter", "Roboto", -apple-system, BlinkMacSystemFont, sans-serif',
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '16px',
          fontWeight: 500,
          minWidth: 100,
        }
      }
    }
  }

});
export default theme;
