import React from 'react';
import {Box, Grid, Container} from '@mui/material';
import './Header.css';
import {getOrangeLogoPair} from "../utils";

const Header = () => {

    const pair = getOrangeLogoPair();

    return (
        <header className="black">
            <Container className="header-wrap">
                <Grid container alignItems="center">
                    <Grid item xs={4}>
                        <Box component="a" href="/" alt="link to homepage" className="top-wordmark">
                            <img src={pair.logo} alt="Elevaide Logo" className="elevaide-logo-image" />
                        </Box>
                    </Grid>
                    <Grid item xs={4} className="main-navigation">
                        {/*<Box className="show-backdrop greyed-out">*/}
                        {/*    <Typography component="div">Product</Typography>*/}
                        {/*</Box>*/}
                        {/*<Box className="show-backdrop greyed-out">*/}
                        {/*    <Typography component="div">Company</Typography>*/}
                        {/*</Box>*/}
                        {/*<Box component="a" href="/product" className="show-backdrop">*/}
                        {/*    <Typography component="div">Products</Typography>*/}
                        {/*</Box>*/}
                        {/*<Box component="a" href="/company" className="show-backdrop">*/}
                        {/*    <Typography  component="div">Company</Typography>*/}
                        {/*</Box>*/}
                    </Grid>
                    <Grid item xs={4} container justifyContent="flex-end">
                        <Box component="a" href="/" alt="link to homepage" className="top-wordmark">
                            <img src={pair.elevaide} alt="Elevaide" className="elevaide-image" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </header>
    );
};

export default Header;
