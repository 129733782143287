import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
    Tooltip,
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    Collapse,
    Box,
    styled,
    useTheme,
    CircularProgress,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import MenuBookIcon from '@mui/icons-material/MenuBook';


const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 400,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius * 2,
    '&:hover': {
        boxShadow: theme.shadows[6],
    },
}));

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.primary,
        boxShadow: theme.shadows[3],
        fontSize: theme.typography.pxToRem(12),
        maxWidth: 'none',
        padding: 0,
    },
    '& .MuiTooltip-arrow': {
        color: theme.palette.background.paper,
    },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
    padding: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
    fontWeight: 600,
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    '& svg': {
        marginRight: theme.spacing(1),
    },
}));


const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    if (hasError) {
        return <Typography color="error">Something went wrong.</Typography>;
    }

    return children;
};

const IndicatorCard = ({ indicator, indicatorCards, isLoading }) => {
    const [examplesExpanded, setExamplesExpanded] = useState(false);
    const [criteriaExpanded, setCriteriaExpanded] = useState(false);
    const [literatureExpanded, setLiteratureExpanded] = useState(false);
    const theme = useTheme();
    
    const card = useMemo(() => indicatorCards[indicator] || {}, [indicator, indicatorCards]);
    
    if (isLoading) {
        return <CircularProgress size={24} />;
    }

    if (!card || Object.keys(card).length === 0) {
        return null;
    }

    const handleExpandClick = (setter) => (event) => {
        event.preventDefault();
        event.stopPropagation();
        setter((prev) => !prev);
    };

    const renderList = (items, defaultMessage) => (
        <List dense sx={{ pl: 2 }}>
            {items && items.length > 0 ? (
                items.map((item, index) => (
                    <ListItem key={index} disablePadding>
                        <ListItemIcon sx={{ minWidth: 28 }}>
                            <Typography color="primary">•</Typography>
                        </ListItemIcon>
                        <ListItemText 
                            primary={typeof item === 'string' ? item : item.description}
                            secondary={item.weight && `Weight: ${item.weight}`}
                            primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                            secondaryTypographyProps={{ variant: 'body2' }}
                        />
                    </ListItem>
                ))
            ) : (
                <ListItem>
                    <ListItemText 
                        primary={defaultMessage}
                        primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                    />
                </ListItem>
            )}
        </List>
    );

    const tooltipContent = (
        <StyledCard>
            <StyledCardContent>
                <SectionTitle variant="subtitle1" gutterBottom>
                    <InfoOutlinedIcon color="primary" />
                    Description
                </SectionTitle>
                <Typography variant="body2" paragraph color="text.secondary">
                    {card.description || "No description available."}
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <SectionTitle variant="subtitle1">
                        <BarChartIcon color="primary" />
                        Scoring Criteria
                    </SectionTitle>
                    <Tooltip title={criteriaExpanded ? "Hide criteria" : "Show criteria"}>
                        <IconButton
                            onClick={handleExpandClick(setCriteriaExpanded)}
                            aria-expanded={criteriaExpanded}
                            aria-label="toggle criteria"
                            size="small"
                        >
                            <ExpandMoreIcon 
                                sx={{
                                    transform: criteriaExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Collapse in={criteriaExpanded} timeout="auto" unmountOnExit>
                    {renderList(card.criteria, "No criteria available")}
                </Collapse>

                <Divider sx={{ my: 2 }} />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <SectionTitle variant="subtitle1">
                        <MenuBookIcon color="primary" />
                        Literature
                    </SectionTitle>
                    <Tooltip title={literatureExpanded ? "Hide literature" : "Show literature"}>
                        <IconButton
                            onClick={handleExpandClick(setLiteratureExpanded)}
                            aria-expanded={literatureExpanded}
                            aria-label="toggle literature"
                            size="small"
                        >
                            <ExpandMoreIcon 
                                sx={{
                                    transform: literatureExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Collapse in={literatureExpanded} timeout="auto" unmountOnExit>
                    <Typography variant="body2" color="text.secondary" paragraph>
                        {card.literature || "No literature information available."}
                    </Typography>
                </Collapse>

                <Divider sx={{ my: 2 }} />

                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <SectionTitle variant="subtitle1">
                        <LightbulbOutlinedIcon color="primary" />
                        Examples
                    </SectionTitle>
                    <Tooltip title={examplesExpanded ? "Hide examples" : "Show examples"}>
                        <IconButton
                            onClick={handleExpandClick(setExamplesExpanded)}
                            aria-expanded={examplesExpanded}
                            aria-label="toggle examples"
                            size="small"
                        >
                            <ExpandMoreIcon 
                                sx={{
                                    transform: examplesExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                    transition: theme.transitions.create('transform', {
                                        duration: theme.transitions.duration.shortest,
                                    }),
                                }}
                            />
                        </IconButton>
                    </Tooltip>
                </Box>

                <Collapse in={examplesExpanded} timeout="auto" unmountOnExit>
                    {renderList(card.examples, "No examples available")}
                </Collapse>

            </StyledCardContent>
        </StyledCard>
    );

    return (
        <ErrorBoundary>
            <StyledTooltip 
                title={tooltipContent} 
                placement="bottom-start" 
                arrow
                // interactive={"True"}
                leaveDelay={300}
            >
                <IconButton 
                    size="small" 
                    color="primary" 
                    aria-label={`Info about ${indicator}`}
                    sx={{
                        '&:hover': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    }}
                >
                    <InfoOutlinedIcon fontSize="small" />
                </IconButton>
            </StyledTooltip>
        </ErrorBoundary>
    );
};

IndicatorCard.propTypes = {
    indicator: PropTypes.string.isRequired,
    indicatorCards: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
};

IndicatorCard.defaultProps = {
    isLoading: false,
};

export default React.memo(IndicatorCard);


// const IndicatorCard = ({ indicator, indicatorCards }) => {
//     const [examplesExpanded, setExamplesExpanded] = useState(false);
//     const [criteriaExpanded, setCriteriaExpanded] = useState(false);
//     const [literatureExpanded, setLiteratureExpanded] = useState(false);
//     const theme = useTheme();
//     const card = indicatorCards[indicator] || {};
    
//     if (!card || Object.keys(card).length === 0) {
//         return null;
//     }

//     const handleExamplesExpandClick = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         setExamplesExpanded(!examplesExpanded);
//     };

//     const handleCriteriaExpandClick = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         setCriteriaExpanded(!criteriaExpanded);
//     };

//     const handleLiteratureExpandClick = (event) => {
//         event.preventDefault();
//         event.stopPropagation();
//         setLiteratureExpanded(!literatureExpanded);
//     };

//     const tooltipContent = (
//         <StyledCard>
//             <StyledCardContent>
//                 <SectionTitle variant="subtitle1" gutterBottom>
//                     <InfoOutlinedIcon color="primary" />
//                     Description
//                 </SectionTitle>
//                 <Typography variant="body2" paragraph color="text.secondary">
//                     {card.description}
//                 </Typography>

//                 <Divider sx={{ my: 2 }} />

//                 <Box display="flex" alignItems="center" justifyContent="space-between">
//                     <SectionTitle variant="subtitle1">
//                         <BarChartIcon color="primary" />
//                         Scoring Criteria
//                     </SectionTitle>
//                     <Tooltip title={criteriaExpanded ? "Hide criteria" : "Show criteria"}>
//                         <IconButton
//                             onClick={handleCriteriaExpandClick}
//                             aria-expanded={criteriaExpanded}
//                             aria-label="toggle criteria"
//                             size="small"
//                         >
//                             <ExpandMoreIcon 
//                                 sx={{
//                                     transform: criteriaExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
//                                     transition: theme.transitions.create('transform', {
//                                         duration: theme.transitions.duration.shortest,
//                                     }),
//                                 }}
//                             />
//                         </IconButton>
//                     </Tooltip>
//                 </Box>

//                 <Collapse in={criteriaExpanded} timeout="auto" unmountOnExit>
//                     <List dense sx={{ pl: 2 }}>
//                         {card.criteria && Array.isArray(card.criteria) ? (
//                             card.criteria.map((criterion, index) => (
//                                 <ListItem key={index} disablePadding>
//                                     <ListItemIcon sx={{ minWidth: 28 }}>
//                                         <Typography color="primary">•</Typography>
//                                     </ListItemIcon>
//                                     <ListItemText 
//                                         primary={`${criterion.description} (Weight: ${criterion.weight})`}
//                                         primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
//                                     />
//                                 </ListItem>
//                             ))
//                         ) : (
//                             <ListItem>
//                                 <ListItemText 
//                                     primary="No criteria available"
//                                     primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
//                                 />
//                             </ListItem>
//                         )}
//                     </List>
//                 </Collapse>

//                 <Divider sx={{ my: 2 }} />

//                 <Box display="flex" alignItems="center" justifyContent="space-between">
//                     <SectionTitle variant="subtitle1">
//                         <MenuBookIcon color="primary" />
//                         Literature
//                     </SectionTitle>
//                     <Tooltip title={literatureExpanded ? "Hide literature" : "Show literature"}>
//                         <IconButton
//                             onClick={handleLiteratureExpandClick}
//                             aria-expanded={literatureExpanded}
//                             aria-label="toggle literature"
//                             size="small"
//                         >
//                             <ExpandMoreIcon 
//                                 sx={{
//                                     transform: literatureExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
//                                     transition: theme.transitions.create('transform', {
//                                         duration: theme.transitions.duration.shortest,
//                                     }),
//                                 }}
//                             />
//                         </IconButton>
//                     </Tooltip>
//                 </Box>

//                 <Collapse in={literatureExpanded} timeout="auto" unmountOnExit>
//                     <Typography variant="body2" color="text.secondary" paragraph>
//                         {card.literature || "No literature information available."}
//                     </Typography>
//                 </Collapse>

//                 <Divider sx={{ my: 2 }} />

//                 <Box display="flex" alignItems="center" justifyContent="space-between">
//                     <SectionTitle variant="subtitle1">
//                         <LightbulbOutlinedIcon color="primary" />
//                         Examples
//                     </SectionTitle>
//                     <Tooltip title={examplesExpanded ? "Hide examples" : "Show examples"}>
//                         <IconButton
//                             onClick={handleExamplesExpandClick}
//                             aria-expanded={examplesExpanded}
//                             aria-label="toggle examples"
//                             size="small"
//                         >
//                             <ExpandMoreIcon 
//                                 sx={{
//                                     transform: examplesExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
//                                     transition: theme.transitions.create('transform', {
//                                         duration: theme.transitions.duration.shortest,
//                                     }),
//                                 }}
//                             />
//                         </IconButton>
//                     </Tooltip>
//                 </Box>

//                 <Collapse in={examplesExpanded} timeout="auto" unmountOnExit>
//                     <List dense sx={{ pl: 2 }}>
//                         {card.examples && Array.isArray(card.examples) ? (
//                             card.examples.map((example, index) => (
//                                 <ListItem key={index} disablePadding>
//                                     <ListItemIcon sx={{ minWidth: 28 }}>
//                                         <Typography color="primary">•</Typography>
//                                     </ListItemIcon>
//                                     <ListItemText 
//                                         primary={example} 
//                                         primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
//                                     />
//                                 </ListItem>
//                             ))
//                         ) : (
//                             <ListItem>
//                                 <ListItemText 
//                                     primary="No examples available"
//                                     primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
//                                 />
//                             </ListItem>
//                         )}
//                     </List>
//                 </Collapse>

//             </StyledCardContent>
//         </StyledCard>
//     );

//     return (
//         <StyledTooltip 
//             title={tooltipContent} 
//             placement="bottom-start" 
//             arrow
//             interactive
//             leaveDelay={300}
//         >
//             <IconButton 
//                 size="small" 
//                 color="primary" 
//                 aria-label={`Info about ${indicator}`}
//                 sx={{
//                     '&:hover': {
//                         backgroundColor: theme.palette.primary.light,
//                     },
//                 }}
//             >
//                 <InfoOutlinedIcon fontSize="small" />
//             </IconButton>
//         </StyledTooltip>
//     );
// };

// export default IndicatorCard;
