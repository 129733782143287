import React, {useState, useEffect} from 'react';
import axios from 'axios';
import config from '../../config';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
    Tabs,
    Tab,
    // IconButton,
    CircularProgress
} from '@mui/material';
import MeetingAnalysis from './meetinganalysis/MeetingAnalysis';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import './Meeting.css';
import {useParams} from "react-router-dom";
import Indicators from "./Indicators";
// import Survey from "./Survey";
// import SpeakerOverview from "./SpeakerOverview";
import Heartbeat from "./Heartbeat/Heartbeat";
import {RefreshProvider, useRefresh} from "../../auth/RefreshProvider";


const MeetingContent = () => {
    const [selectedMeetingMetrics, setSelectedMeetingMetrics] = useState(null);
    const [selectedMeetingTranscriptData, setSelectedMeetingTranscriptData] = useState({
        transcript: {utterances: [], speaker_names: []},
        metaData: {}
    });
    const { selectedMeetingId } = useParams();
    const { refreshKey, triggerRefresh } = useRefresh();

    const [tabIndex, setTabIndex] = useState(0);
    const [loadingMetrics, setLoadingMetrics] = useState(false);

    // Add a new useEffect specifically for handling refreshKey changes
    useEffect(() => {
        if (selectedMeetingId) {
            fetchMeetingMetrics(selectedMeetingId);
        }
    }, [selectedMeetingId, refreshKey]);

    const fetchMeetingMetrics = async (meetingId) => {
        setLoadingMetrics(true);
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-metrics/`, {
                params: {
                    selectedMeetingId: meetingId,
                    getTranscript: true,
                    getSurvey: false,
                    getSummary: true
                },
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = response.data;
            if (data.meetingMetrics) {
                setSelectedMeetingMetrics(data.meetingMetrics);
                let transcriptData = {
                    'transcript': data.transcript,
                    'metaData': data.metaData,
                    'summary': data.summary
                }
                setSelectedMeetingTranscriptData(transcriptData);
                // setSelectedMeetingSurvey(data.survey);
            }

            
        } catch (error) {
            console.error('Error fetching meeting metrics:', error);
        } finally {
            setLoadingMetrics(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
    };

    // const setSurveyTaken = (taken) => {
    //     setSelectedMeetingSurvey((prevSurvey) => ({
    //         ...prevSurvey,
    //         surveyTaken: taken,
    //     }));
    // };

    return (
        <Box>
            <Box className="tab-curved-view">
                <Box className="tab-header">
                    <Tabs 
                        value={tabIndex} 
                        onChange={handleTabChange} 
                        aria-label="Meeting Tabs"
                        sx={{
                            '& .MuiTab-root': {
                            textTransform: 'none',
                            fontSize: '16px',
                            fontWeight: 500,
                            minWidth: 100,
                            }
                        }}
                        >
                        <Tab label="Overview"/>
                        <Tab label="Details"/>
                        <Tab label="Timeline"/>
                    </Tabs>
                </Box>
                <Box className="tab-panel" hidden={tabIndex !== 0}>
                    <Grid container>
                        {loadingMetrics ? (
                            <Grid item xs={12} className="loading-indicator">
                                <CircularProgress/>
                            </Grid>
                        ) : (
                            selectedMeetingMetrics && (
                                <Grid item xs={12}>
                                    <MeetingAnalysis 
                                        data={selectedMeetingMetrics}
                                        transcript={selectedMeetingTranscriptData}                                    />
                                </Grid>
                            )
                        )}
                    </Grid>
                </Box>
                <Box className="tab-panel" hidden={tabIndex !== 1}>
                    <Grid container>
                        {loadingMetrics ? (
                            <Grid item xs={12} className="loading-indicator">
                                <CircularProgress/>
                            </Grid>
                        ) : (
                            selectedMeetingMetrics && (
                                <Grid item xs={12}>
                                    <Indicators indicatorsData={selectedMeetingMetrics}
                                                transcript={selectedMeetingTranscriptData}/>
                                </Grid>
                            )
                        )}
                    </Grid>
                </Box>
                <Box className="tab-panel" hidden={tabIndex !== 2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Heartbeat
                                transcriptData={selectedMeetingTranscriptData}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

const Meeting = () => {
    return (
        <RefreshProvider>
            <MeetingContent/>
        </RefreshProvider>
    );
};

export default Meeting;


// <Box className="tab-panel" hidden={tabIndex !== 2}>
//     <Grid container>
//         <Grid item xs={12}>
//             <Survey survey={selectedMeetingSurvey} meetingId={selectedMeetingId}
//                     setSurveyTaken={setSurveyTaken}/>
//         </Grid>
//     </Grid>
// </Box> */}

// const fetchMeetingMetrics = async (meetingId) => {
//     setLoadingMetrics(true);
//     setSelectedMeetingId(meetingId);
//     try {
//         const response = await axios.get(`${config.backendUrlApiV1}/get-meeting-metrics/`, {
//             params: {
//                 selectedMeetingId: meetingId,
//                 getTranscript: true,
//                 getSurvey: true,
//                 getEngagement: true
//             },
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//         });
//         const data = response.data;
//         if (data.meetingMetrics) {
//             setSelectedMeetingMetrics(data.meetingMetrics);
//             setSelectedMeetingTranscript(data.transcript)
//             setSelectedMeetingSurvey(data.survey)
//             setSelectedEngagement(data.engagement); // Set the engagement data
//         }
//     } catch (error) {
//         console.error('Error fetching speaker data:', error);
//     } finally {
//         setLoadingMetrics(false);
//     }
// };
