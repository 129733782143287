import axios from 'axios';
import {createContext, useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import config from '../config';
import {trackAuth} from "../common/googleanalytics";

const AuthContext = createContext(null);

axios.defaults.withCredentials = true;

// Set up axios interceptor for auth token
axios.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = `Token ${token}`;
    }
    return config;
});


export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    // Initialize authentication on app load
    useEffect(() => {
        const initializeAuth = () => {
            try {
                const savedUser = localStorage.getItem('user');
                const token = localStorage.getItem('token');
                
                if (savedUser && token) {
                    const parsedUser = JSON.parse(savedUser);
                    setUser(parsedUser);
                }
            } catch (error) {
                console.error('Auth initialization error:', error);
                clearAuthData();
            } finally {
                setIsLoading(false); // Mark initialization as complete
            }
        };

        initializeAuth();
    }, []);

    // Clear authentication data from storage and state
    const clearAuthData = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        setUser(null);
        setError('');
    };

    // Handle login and save user information
    const handleLogin = async (token, email, provider) => {
        try {
            if (!token || !email) {
                throw new Error('Invalid login credentials');
            }

            const userData = {
                email,
                isAuthenticated: true,
                provider,
                token
            };

            localStorage.setItem('user', JSON.stringify(userData));
            localStorage.setItem('token', token);
            setUser(userData);
            setError('');
            trackAuth.signIn(provider);
        } catch (error) {
            clearAuthData();
            setError(`Error logging in with ${provider}`);
            trackAuth.error(`${provider}_sign_in_error`);
            throw error;
        }
    };

    // Handle logout process
    const logout = async () => {
        try {
            const token = localStorage.getItem('token');
    
            if (token) {
                await axios.post(`${config.backendUrlApiV1}/logout/`, {}, {
                    headers: {
                        Authorization: `Token ${token}`,
                    },
                });
            }
        } catch (error) {
            console.error('Logout error:', error);
            trackAuth.error('sign_out_error');
        } finally {
            clearAuthData();
            trackAuth.signOut();
    
            // Redirect to sign-in page after logout
            navigate('/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3', { replace: true });
        }
    };
    
    return (
        <AuthContext.Provider value={{
            user,
            error,
            isLoading,
            handleLogin,
            logout,
            clearAuthData
        }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use authentication context
export function useAuth() {
    return useContext(AuthContext);
}
