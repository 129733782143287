import React, { useState, useEffect } from 'react';
import {
    Box,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    useTheme
} from '@mui/material';

const TeamFilterDropdown = ({ meetings, onFilterChange, currentFilter }) => {
    const theme = useTheme();
    
    // Get available teams and valid options
    const getFilterOptions = () => {
        const uniqueTeams = new Set();
        let hasNoTeam = false;
        
        meetings.forEach(weekData => {
            weekData.items.forEach(meeting => {
                if (meeting.team) {
                    uniqueTeams.add(meeting.team);
                } else {
                    hasNoTeam = true;
                }
            });
        });

        const options = ['all'];
        options.push(...Array.from(uniqueTeams).sort());
        if (hasNoTeam) {
            options.push('no-team');
        }
        
        return options;
    };

    const [options, setOptions] = useState(['all']);
    const [selectedValue, setSelectedValue] = useState('all');

    // Initialize and update options
    useEffect(() => {
        if (meetings?.length > 0) {
            const newOptions = getFilterOptions();
            setOptions(newOptions);
            
            // Set initial value based on currentFilter
            const initialValue = currentFilter === null ? 'all' 
                : currentFilter === undefined ? 'no-team'
                : currentFilter;
                
            // Only set if it's a valid option
            if (newOptions.includes(initialValue)) {
                setSelectedValue(initialValue);
            } else {
                setSelectedValue('all');
            }
        }
    }, [meetings, currentFilter]);

    const handleTeamChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        
        switch (value) {
            case 'all':
                onFilterChange(null);
                break;
            case 'no-team':
                onFilterChange(undefined);
                break;
            default:
                onFilterChange(value);
        }
    };

    const getDisplayText = (value) => {
        switch (value) {
            case 'all':
                return 'All Meetings';
            case 'no-team':
                return 'No Team';
            default:
                return value;
        }
    };

    return (
        <Box sx={{ minWidth: 200 }}>
            <FormControl fullWidth size="small">
                <InputLabel id="team-filter-label">Filter by Team</InputLabel>
                <Select
                    labelId="team-filter-label"
                    id="team-filter-select"
                    value={selectedValue}
                    label="Filter by Team"
                    onChange={handleTeamChange}
                    sx={{
                        backgroundColor: 'background.paper',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.grey[300],
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                            borderColor: theme.palette.grey[400],
                        },
                    }}
                >
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {getDisplayText(option)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
};

export default TeamFilterDropdown;
