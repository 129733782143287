import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
} from '@mui/material';
import config from '../../config';
import './css/AnalyticsFilters.css';

const datePresets = [
    {label: 'Last 7 days', value: 'last7Days'},
    {label: 'Last 14 days', value: 'last14Days'},
    {label: 'Last 30 days', value: 'last30Days'},
    {label: 'Last 45 days', value: 'last45Days'},
    {label: 'Last 60 days', value: 'last60Days'},
];

const AnalyticsFilters = ({filters, onFilterChange}) => {
    const [teams, setTeams] = useState([]);


    const fetchTeams = async () => {
        try {
            const response = await axios.get(`${config.backendUrlApiV1}/team/user-teams/`);
            setTeams(response.data);
            if (response.data.length > 0) {
                handleChange('teamFilter', response.data[0].id);
            }
        } catch (error) {
            console.error('Error fetching teams:', error);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);


    const handleChange = (filterName, value) => {
        const newFilters = {
            ...filters,
            [filterName]: value
        };
        onFilterChange(newFilters);
    };

    return (
        <Box className="filters-container">
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Date Range</InputLabel>
                        <Select
                            value={filters.dateFilter}
                            onChange={(e) => handleChange('dateFilter', e.target.value)}
                            label="Date Range"
                        >
                            {datePresets.map((preset) => (
                                <MenuItem key={preset.value} value={preset.value}>
                                    {preset.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel>Team</InputLabel>
                        <Select
                            value={filters.teamFilter}
                            onChange={(e) => handleChange('teamFilter', e.target.value)}
                            label="Team"
                        >
                            {teams.map((team) => (
                                <MenuItem key={team.id} value={team.id}>
                                    {team.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AnalyticsFilters;
