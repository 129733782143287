import React, {useEffect} from 'react';
import {useAuth} from "./AuthProvider";
import {useNavigate, useLocation} from "react-router-dom";
import {CircularProgress, Box} from '@mui/material';

const AuthCallback = () => {
    const {handleLogin} = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');
        const email = params.get('email');
        const provider = params.get('provider');

        console.log('Auth callback received:', { token, email, provider });

        if (token && email && provider) {
            handleLogin(token, email, provider);
            navigate('/product', { replace: true });
        } else {
            console.error('Missing auth parameters:', { token, email, provider });
            navigate('/sign-in-3b9fAll7c5aY0u8d1eN33d4e6a8iS7d5b9cL0V36a7d8e9b3', { replace: true });
        }
    }, [location, navigate, handleLogin]);

    return (
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
            <CircularProgress />
        </Box>
    );
};

export default AuthCallback;
